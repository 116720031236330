<script>
  import { Link } from "svelte-routing";
  import Header from './components/Header.svelte';
  let games = [];
  export let id;
  async function game_report(id) {
    const res = await fetch('/api/get_games/' + id)
    games = await res.json();
  }
  game_report(id)
  function espn(x) {
      if (x === 'UTA') return 'UTAH';
      else if (x === 'NOP') return 'NO';
      else return x;
  }
  function formatDate(date) {
    const [year, month, day] = date.split("-");
    return `${parseInt(month)}/${parseInt(day)}/${year % 100}`;
  }
</script>

<Header titlePart1="POR" titlePart2="GAMES"/>

<div class="menu">
    {#each games as g}
        <Link to="game_report/{g[0]}">
            <div class="team_box">
                <img alt="{g[2]}" src="https://a.espncdn.com/i/teamlogos/nba/500/{espn(g[2].split(' ')[1])}.png"/><br/>
                {formatDate(g[1])} {g[2]}
            </div>
        </Link>
    {/each}
</div>

<style>
    .team_box {
        margin: 10px;
        padding: 10px;
        width: 150px;
        height: 170px;
        text-align: center;
        background: #dddddd;
        display: inline-block;
    }

    .team_box:hover {
        background: #bbbbbb;
    }

    img {
        width: 120px;
    }
</style>
