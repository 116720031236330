<script>
  import { Link } from "svelte-routing";
  import Header from './components/Header.svelte';

  const teams = ['ATL', 'BKN', 'BOS', 'CHA', 'CHI', 'CLE', 'DAL', 'DEN', 'DET', 'GSW',
               'HOU', 'IND', 'LAC', 'LAL', 'MEM', 'MIA', 'MIL', 'MIN', 'NOP', 'NYK',
               'OKC', 'ORL', 'PHI', 'PHX', 'POR', 'SAC', 'SAS', 'TOR', 'UTA', 'WAS'];
  const ourTeam = "POR";

  function espn(x) {
      if (x === 'UTA') return 'UTAH';
      else if (x === 'NOP') return 'NO';
      else return x;
  }

</script>

<Header titlePart1="NBA" titlePart2="EXPLORER"/>

<div class="menu">
    {#each teams as t}
        <div class="team_box team_box_{t}" style="background-image: url(https://a.espncdn.com/i/teamlogos/nba/500/{espn(t)}.png);">
            <div class="team-title">{t}</div>
            <div class="side-reports">
                <Link to="team_def_report/{t}">Defense</Link>
                <div>|</div>
                <Link to="team_off_report/{t}">Offense</Link>
            </div>
        </div>
    {/each}
</div>
<div class="menu">
    <div class="team_box team_box-misc">
        <Link to="explorer">
            <img src="https://www.clipartmax.com/png/full/187-1879182_magnifying-glass-graphic-design-designer-magnifying-glass-vector-flat.png" alt="EXPLORER"/>
            Explorer
        </Link>
    </div>
    <div class="team_box team_box-misc">
        <Link to="games/{ourTeam}">
            <img src="https://a.espncdn.com/i/teamlogos/nba/500/{ourTeam}.png" alt="{ourTeam} GAMES"/>
            {ourTeam} Games
        </Link>
    </div>
</div>

<style>
    .menu {
        display: flex;
        flex-wrap: wrap;
    }

    .team_box {
        margin: 10px;
        padding: 10px;
        width: 150px;
        background: #e6e6e6;
        background-size: 120%;
        background-repeat: no-repeat;
        background-position: 50px center;
        background-blend-mode: overlay;
        display: flex;
        flex-direction: column;
        font-family: 'BentonSans Bold', sans-serif;

    }
    .team_box-misc {
        text-align: center;
    }

    .team-title {
        font-weight: bold;
        font-size: 2em;
    }

    img {
        width: 120px;
    }

    .side-reports {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }
</style>
