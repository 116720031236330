<script>
    import FormFilter from "./FormFilter.svelte";
    import Select from 'svelte-select';
    import { slide } from 'svelte/transition';
    import MdClose from 'svelte-icons/md/MdClose.svelte';
    export let form, id, filters, metrics;
    let removeMetricGroup = i => () => {
         $form[i].metrics = 'this_is_hidden';
         // TODO: Figure out a more elegant solution!
         // This one requires "clean_query" in server.py
         // Straightforward approach (eliminate $form[i]) seemingly not working
    };
</script>

{#if $form[id].metrics !== 'this_is_hidden'}
    <div class="metric_group" transition:slide>
        {#if id > 0}
            <div class="icon" on:click={removeMetricGroup(id)}><MdClose /></div>
            <h1>Metrics</h1>
            <div class="metrics_input">
                <Select items={metrics} isMulti={true} bind:selectedValue={$form[id].metrics} />
            </div>
        {/if}
        <h1>{#if id > 0}Metric {/if}Breakdowns</h1>
        <FormFilter bind:form={form} elem={'dimensions'} id={id} filters={filters} />
        <h1>{#if id > 0}Metric {/if}Filters</h1>
        <FormFilter bind:form={form} elem={'filters'} id={id} filters={filters} />
        {#if id > 0}
            <h1>Ratio</h1>
            <div class="ratio_input">
                <Select items={metrics} bind:selectedValue={$form[id].ratio} />
            </div>
        {/if}
    </div>
{/if}

<style>
    .metric_group {
        padding: 10px;
        background: #EEEEEE;
        margin: 15px;
    }
    .metrics_input {
        display: flex;
        --border: none;
        --multiItemActiveBG: #DCDDDE;
        --multiClearHoverFill: #DCDDDE;
    }
    .ratio_input {
        width: 210px;
        --border: none;
        --multiItemActiveBG: #DCDDDE;
        --multiClearHoverFill: #DCDDDE;
    }
    h1 {
        color: #DCDDDE;
        text-transform: uppercase;
        font-family: "BentonSans Medium", sans-serif;
        font-size: 0.7em;
        margin: 15px 1px 5px 1px;
    }
    .icon {
        color: #333;
        width: 15px;
        height: 15px;
        padding: 5px;
        background: #bfbfbf57;
        border-radius: 15px;
        float: right;
    }
    .icon:hover {
        color: white;
        background: #DCDDDE60;
        cursor: pointer;
    }
</style>