<script>
    import Header from './components/Header.svelte';
    import Sidebar from './components/Sidebar.svelte';
    import Table from './components/Table.svelte';
    import SaveContent from './components/SaveContent.svelte';
    import Form from './components/Form.svelte';
    import {createForm} from 'svelte-forms-lib';
    import Modal from 'svelte-simple-modal';
    import {scale, fade} from 'svelte/transition';
    import Spinner from './components/Spinner.svelte';
    import MdViewList from 'svelte-icons/md/MdViewList.svelte';
    import MdNoteAdd from 'svelte-icons/md/MdNoteAdd.svelte';

    // Initialization
    export let id = '';
    if (id) {
        loadQuery('', id)
    }
    let data = [{}];
    let queries = [];
    let filters = {};
    let metrics = [];
    let sidebarStatus = 'load';
    let tableStatus = false;
    let flashMessage = false;
    let queryName = false;
    const {form, handleSubmit} = createForm({
        initialValues: [
            {
                metrics: undefined,
                dimensions: [{name: undefined, op: undefined, values: undefined, params: 0}],
                filters: [{name: undefined, op: undefined, values: undefined, params: 0}],
                ratio: null
            },
            {
                metrics: undefined,
                dimensions: [{name: undefined, op: undefined, values: undefined, params: 0}],
                filters: [{name: undefined, op: undefined, values: undefined, params: 0}],
                ratio: null
            }
        ],
        onSubmit: values => {
            executeQuery(values, queryName || null);
        }
    });
    initialize();

    async function initialize(skipFilters = false, skipMetrics = false) {
        const que = await fetch('/explorer/api/load_queries')
        queries = await que.json();
        if (!skipFilters) {
            const fil = await fetch('/explorer/api/get_filters')
            filters = await fil.json();
        }
        if (!skipMetrics) {
            const met = await fetch('/explorer/api/get_metrics')
            metrics = await met.json();
        }
    }

    // Query Handling
    let setFlashMessage = (message, goaway) => {
        flashMessage = message;
        if (goaway) setTimeout(() => {
            flashMessage = false
        }, 2000);
    }

    async function executeQuery(q, name = null) {
        sidebarStatus = false;
        tableStatus = 'loading';
        setFlashMessage(false, false);
        try {
            const res = await fetch('/explorer/api/exec_query', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(q)
            })
            data = await res.json();
            tableStatus = 'loaded';
            // Disable auto-save query
            // const q_id = await saveQuery(name, true)
            // let query_id = await q_id.json()
            // history.pushState(null, null, '/explorer/' + query_id['query_id'])
        } catch (err) {
            tableStatus = false;
            setFlashMessage("Query didn't run properly. Adjust and try again.", false);
        }
    }

    async function loadQuery(index = null, query_id = null) {
        let sel_query;
        if (index) {
            sel_query = JSON.parse(queries[index]['QUERY']);
        } else {
            const q = await fetch('/explorer/api/load_queries/' + query_id)
            sel_query = await q.json()
            sel_query = JSON.parse(sel_query[0]['QUERY']);
        }
        let max_length = (sel_query.length > $form.length) ? sel_query.length : $form.length;
        for (let j = 0; j < max_length; j++) {
            if (j > 1) {
                $form.push({
                    metrics: undefined,
                    dimensions: [{name: undefined, op: undefined, values: undefined, params: 0}],
                    filters: [{name: undefined, op: undefined, values: undefined, params: 0}],
                    ratio: null
                })
            }
            if (j < sel_query.length) {
                $form[j].metrics = sel_query[j]['metrics'];
                $form[j].dimensions = sel_query[j]['dimensions'];
                $form[j].filters = sel_query[j]['filters'];
                $form[j].ratio = sel_query[j]['ratio'];
            } else {
                $form[j].metrics = 'this_is_hidden';
            }
        }
        queryName = index ? queries[index]['NAME'] : null;
        await executeQuery(sel_query, queryName);
    }

    async function saveQuery(name, query_run = false) {
        setFlashMessage(false, false);
        let query_id;
        let queryBody = {query: $form};
        if (name) {
            queryBody["name"] = name;
        }
        try {
            queryName = name;
            query_id = await fetch('/explorer/api/save_query', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(queryBody),
            });
            !query_run && setFlashMessage("Query saved successfully", true);
            await initialize(true, true);
        } catch {
            !query_run && setFlashMessage("Query could not be saved", false);
        }
        return query_id;
    }
</script>

<!-- Header -->
<Header titlePart1="NBA" titlePart2="EXPLORER">
    {#if queryName}
        <div id="query_name" style="">{queryName}</div>
    {/if}
    {#if tableStatus === 'loaded'}
        <div class="icon" style="float: left" transition:fade>
            <Modal>
                <SaveContent queryName={queryName} queries={queries} handleSave={saveQuery}/>
            </Modal>
        </div>
    {/if}
    <div class="icon" style="float: left" on:click={() => {sidebarStatus = 'edit'}} title="New Query">
        <MdNoteAdd/>
    </div>
    <div class="icon" style="float: right" on:click={() => {sidebarStatus = 'load'}} title="Load Query">
        <MdViewList/>
    </div>
</Header>

<!-- Flash Message -->
{#if flashMessage}
    <div id='flash-message' on:click={() => {setFlashMessage(false, false)}} transition:fade>{flashMessage}</div>
{/if}

<!-- Sidebar -->
{#if sidebarStatus === 'load'}
    <Sidebar bind:status={sidebarStatus} width="300">
        <div id="sidebar-title">Saved Queries</div>
        {#each queries as query, i}
            <div class="query_list_item" on:click={() => {loadQuery(i)}}>{query.NAME}</div>
        {/each}
    </Sidebar>
{:else if sidebarStatus === 'edit'}
    <Sidebar bind:status={sidebarStatus} width="700">
        <Form form={form} handleSubmit={handleSubmit} filters={filters} metrics={metrics}/>
    </Sidebar>
{/if}

<!-- Table -->
{#if tableStatus === 'loading'}
    <Spinner/>
{:else if tableStatus === 'loaded'}
    <div id="data-table" transition:fade>
        <Table data={data} enableFilters={true}/>
    </div>
{/if}

<style>
    #data-table {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
    }

    #flash-message {
        width: 400px;
        background: #A6192E;
        color: #000100;
        padding: 10px;
        text-align: center;
        position: absolute;
        left: 35%;
        top: 60px;
        font-family: "BentonSans Medium", sans-serif;
        border-radius: 30px;
    }

    .icon {
        margin: 3px 10px;
        color: #EEEEEE;
        width: 24px;
        height: 24px;
    }

    .icon:hover {
        color: #A6192E;
        cursor: pointer;
    }

    #query_name {
        float: left;
        margin-right: 10px;
        margin-top: 5px;
        color: #EEEEEE;
    }

    #sidebar-title {
        margin-top: 40px;
        margin-left: 20px;
        margin-bottom: 20px;
        background-color: #DCDDDE;
        color: white;
        border-bottom-left-radius: 20px 20px;
        border-top-left-radius: 20px 20px;
        padding: 5px 5px 5px 10px;
        text-transform: uppercase;
        font-family: "BentonSans Medium", sans-serif;
        font-size: 0.8em;
        width: 100%;
    }

    .query_list_item {
        margin-left: 30px;
        margin-bottom: 5px;
    }

    .query_list_item:hover {
        color: #A6192E;
        cursor: pointer;
    }
</style>