<script>
    import Select from 'svelte-select';
    import MdAdd from 'svelte-icons/md/MdAdd.svelte';
    import MdRemove from 'svelte-icons/md/MdRemove.svelte';
    import MdCallSplit from 'svelte-icons/md/MdCallSplit.svelte'

    export let form, id, elem, filters;
    const add = () => {
        $form[id][elem] = $form[id][elem].concat({name: undefined, op: undefined, values: undefined, params: 0});
    };
    const remove = i => () => {
        $form[id][elem] = $form[id][elem].filter((u, j) => j !== i);
    };
    const parameter = i => () => {
        $form[id][elem][i].params = 1;
    };
    const clearFields = (e, j) => {
        $form[id][elem][j].op = filters[e.detail.value].ops[0];
        $form[id][elem][j].values = undefined;
    }
</script>

<form>
    {#each $form[id][elem] as fil, j}
        <div class="form-group">
            <div class="name_select">
                <Select
                    items={Object.keys(filters)}
                    on:select={(e) => clearFields(e, j)}
                    on:clear={() => {$form[id][elem][j].params = 0}}
                    bind:selectedValue={$form[id][elem][j].name}
                />
            </div>
            {#if $form[id][elem][j].name !== undefined && $form[id][elem][j].name.value !== "" && (elem !== 'dimensions' || $form[id][elem][j].params === 1)}
                <div class="op_select">
                    <Select
                        items={filters[$form[id][elem][j].name.value].ops}
                        on:select={(e) => $form[id][elem][j].op = e.detail}
                        selectedValue={$form[id][elem][j].op}
                    />
                </div>
            {/if}
            {#if $form[id][elem][j].name !== undefined && $form[id][elem][j].name.value !== "" && (elem !== 'dimensions' || $form[id][elem][j].params === 1)}
                {#if filters[$form[id][elem][j].name.value].type === 'values'}
                    <Select
                        items={filters[$form[id][elem][j].name.value].values}
                        on:select={(e) => $form[id][elem][j].values = e.detail}
                        selectedValue={$form[id][elem][j].values}
                        isMulti={true}
                    />
                {:else}
                    <input
                        placeholder="Enter value"
                        bind:value={$form[id][elem][j].values}
                        class="value_input"
                    />
                {/if}
            {/if}

            {#if elem === 'dimensions' && !$form[id][elem][j].params && $form[id][elem][j].name !== undefined}
                <div class="icon icon-remove" on:click={parameter(j)}><MdCallSplit /></div>
            {/if}

            {#if $form[id][elem].length !== 1}
                <div class="icon icon-remove" on:click={remove(j)}><MdRemove /></div>
            {/if}
        </div>
        {#if j === $form[id][elem].length - 1}
            <div class="icon icon-add" on:click={add}><MdAdd /></div>
        {/if}
    {/each}
</form>

<style>
    .form-group {
        display: flex;
        --border: none;
        --multiItemActiveBG: #DCDDDE;
        --multiClearHoverFill: #DCDDDE;
    }
    .value_input {
        border: none;
        padding-left: 15px;
        font-size: 14px;
        border-radius: 3px;
        width: 175px;
    }
    .name_select {
        width: 210px;
    }
    .op_select {
        width: 100px;
    }
    .icon {
        min-width: 15px;
        color: #333;
        width: 15px;
        height: 15px;
        padding: 5px;
        background: #bfbfbf57;
        border-radius: 15px;
    }

    .icon-remove {
        margin: 15px 0px 5px 10px;
    }
    .icon-add {
        margin: 5px 0px 10px 5px;
    }

    .icon:hover {
        color: white;
        background: #DCDDDE60;
        cursor: pointer;
    }
</style>
