<script>
    import {getContext} from 'svelte';

    export let message;
    export let hasForm = false;
    export let onCancel = () => {
    };
    export let onOkay = () => {
    };
    export let queryName, queries;
    const {close} = getContext('simple-modal');
    let overwrite = false;
    let value = queryName;

    let onChange = () => {
        overwrite = false;
        for (let i = 0; i < queries.length; i++) {
            overwrite = overwrite || (queries[i]['NAME'] === value)
        }
        queryName = value;
    };

    function _onCancel() {
        onCancel();
        close();
    }

    function _onOkay() {
        onOkay(value);
        queryName = value;
        close();
    }

    $: onChange(value)
</script>


<div id="dialog-title">{message}</div>

{#if hasForm}
    <input
            type="text"
            style="width: 245px"
            bind:value
            on:keydown={e => e.which === 13 && _onOkay()}/>
{/if}

<div class="buttons">
    {#if overwrite}
        <button style="float:right"  on:click={_onOkay}>overwrite</button>
    {:else}
        <button style="float:right" on:click={_onOkay}>save query</button>
    {/if}
</div>

<style>
    #dialog-title {
        margin-bottom: 10px;
        color: #DCDDDE;
        text-transform: uppercase;
        font-family: "BentonSans Medium", sans-serif;
        font-size: 0.8em;
    }

    input {
        width: 270px;
        border: 0px;
    }

    button {
        font-family: "BentonSans Medium", sans-serif;
        margin-top: 5px;
        font-size: 0.7em;
        color: #f9f9f9;
        padding: 8px 8px 5px 8px;
        text-transform: uppercase;
        vertical-align: middle;
        border: none;
        border-radius: 30px;
        background: #DCDDDE;
    }

    button:hover {
        cursor: pointer;
        background: #DCDDDE60;
    }
</style>