<script>
    import MdClose from 'svelte-icons/md/MdClose.svelte';
    import {fly} from 'svelte/transition';
    export let status, width;
</script>

<div id="sidebar" style="width: {width}px" transition:fly={{x:width, duration:1000, opacity: 1.0}}>
    <div id="sidebar-buttons">
        <div class="icon" style="float: left" on:click={() => {status = false}}><MdClose/></div>
    </div>
    <slot />
</div>

<style>
    #sidebar {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: #f9f9f9;
        z-index:100;
        box-shadow: 0 -0.4rem 0.9rem 0.2rem rgba(0, 0, 0, 0.5);
    }
    .icon {
        margin: 5px 5px;
        color: #333;
        width: 15px;
        height: 15px;
    }
    .icon:hover {
        color: #A6192E;
        cursor: pointer;
    }

</style>