<script>
    import { getContext } from 'svelte';
    import Dialog from './SaveDialog.svelte';
    import MdSave from 'svelte-icons/md/MdSave.svelte'
    const { open } = getContext('simple-modal');
    const onCancel = () => {}
    export let handleSave;
    const onOkay = (text) => {
        handleSave(text);
    }
    export let queryName, queries;
    const showDialog = () => {
        open(Dialog,
            { message: 'Save As', hasForm: true, onCancel, onOkay, queryName: queryName, queries: queries },
            {
                closeButton: false, closeOnEsc: true, closeOnOuterClick: true,
                styleWindow: {
                    width: '300px',
                    boxShadow: '0.4rem 0.2rem 0.4rem 0.2rem rgba(0, 0, 0, 0.5)',
                    background: '#EEEEEE'
                }
            }
        );
    };
</script>

<div on:click={showDialog} title="Save Query"><MdSave/></div>