<script>
    import Header from './components/Header.svelte';
    import ReportCard from './components/ReportCard.svelte';
    import Spinner from './components/Spinner.svelte';
    import Table from './components/Table.svelte';

    export let id;
    let data = 'N/A';
    let checkbox = false;
    async function  team_report(id) {
        data = 'N/A';
        let res;
        if (checkbox) {
            res = await fetch('/api/team_off_report/' + id + '/vs_off')
        }
        else {
            res = await fetch('/api/team_off_report/' + id + '/off')
        }
        let sdata = await res.json();
        data = JSON.parse(sdata);
        console.log(data);
    }
    team_report(id);
    let nth = (n) => {return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}
</script>

<svelte:head><title>Offensive Report - {id}</title></svelte:head>
<Header titlePart1="OFFENSIVE" titlePart2="REPORT" />

<div id="content">
    <h1>{id}</h1>
    <label>
        <input type=checkbox bind:checked={checkbox} on:change={e => {team_report(id)}}>
        vs POR Filter
    </label>
    {#if data === 'N/A'}
        <Spinner />
    {:else}
        <h2>Overview</h2>
        <ReportCard data={data['offense']['overall']} error_message={"No Overview available."}/><br/>
        <h2>Pick & Roll</h2>
        <ReportCard data={data['offense']['77']} error_message={"No 77 data available."}/><br/>
        <ReportCard data={data['offense']['MPR']} error_message={"No MPR data available."}/><br/>
        <ReportCard data={data['offense']['SPR']} error_message={"No SPR data available."}/><br/>
        <ReportCard data={data['offense']['step_up']} error_message={"No step up data available."}/><br/>
        <ReportCard data={data['offense']['corner']} error_message={"No corner data available."}/><br/>

        <h3>Stack PR</h3>
        <Table data={data['offense']['stack_pr']} enableFilters={false} thresholdValue="1" />
        <h2>Small Small PR</h2>
        <ReportCard data={data['offense']['small_small_pr_team']} error_message={"No small small PR team data available."}/><br/>
        <ReportCard data={data['offense']['small_small_pr_players']} error_message={"No small small PR player data available."}/><br/>
        <h2>Other Offensive Actions</h2>
        <ReportCard data={data['offense']['dho']} error_message={"No dho data available."}/><br/>
        <ReportCard data={data['offense']['small_small_dho']} error_message={"No small small dho data available."}/><br/>
        <ReportCard data={data['offense']['screening']} error_message={"No small small dho data available."}/><br/>
        <ReportCard data={data['offense']['small_small_screening']} error_message={"No small small screening data available."}/><br/>
        <ReportCard data={data['offense']['iso']} error_message={"No iso data available."}/><br/>
        <ReportCard data={data['offense']['post']} error_message={"No post data available."}/><br/>
        <h2>Special Situations</h2>
        <ReportCard data={data['offense']['zone']} error_message={"No zone data available."}/><br/>
        <h3>Foul to Give</h3>
        <Table data={data['offense']['foul_to_give']} enableFilters={false} thresholdValue="1" />
        <h3>Need 2</h3>
        <Table data={data['offense']['need_2']} enableFilters={false} thresholdValue="1" />
        <h3>Need 3</h3>
        <Table data={data['offense']['foul_up_3_need_3']} enableFilters={false} thresholdValue="1" />
        <h3>Get In</h3>
        <Table data={data['offense']['get_in']} enableFilters={false} thresholdValue="1" />
    {/if}
</div>

<style>
    #content {
        margin: 0 20px 20px 20px;
    }

    h1 {
        font-size: 24px;
        font-family: "BentonSans Black", sans-serif;
        color: #000100;
    }

    h2 {
        font-size: 16px;
        font-family: "BentonSans Bold", sans-serif;
        color: #000100;
        background: #A6192E;
        padding: 4px;
        margin-bottom:0;
    }

    h3 {
        padding: 5px;
        background: #000100;
        color: white;
        font-family: "BentonSans Medium", sans-serif;
        text-transform: uppercase;
        font-size: 0.85em;
        text-align: center;
    }
</style>
