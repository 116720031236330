<script>
  import { Router, Route, } from "svelte-routing";
  import Index from './Index.svelte';
  import Explorer from './Explorer.svelte';
  import TeamDefReport from './TeamDefReport.svelte';
  import TeamOffReport from './TeamOffReport.svelte';
  import GameReport from './GameReport.svelte';
  import Games from './Games.svelte';
  export let url = "";
</script>

<Router url="{url}">
  <div>
    <Route path="/"><Index /></Route>
    <Route path="explorer/"><Explorer /></Route>
    <Route path="explorer/:id" let:params><Explorer id={params.id} /></Route>
    <Route path="team_def_report/:id" let:params><TeamDefReport id={params.id} /></Route>
    <Route path="team_off_report/:id" let:params><TeamOffReport id={params.id} /></Route>
    <Route path="game_report/:id" let:params><GameReport id={params.id} /></Route>
    <Route path="games/:id" let:params><Games id={params.id} /></Route>
  </div>
</Router>