<script>
    import { getContext } from 'svelte';
    import Dialog from './ShotChartDialog.svelte';
    import GiBasketballBasket from 'svelte-icons/gi/GiBasketballBasket.svelte'
    const { open } = getContext('simple-modal');
    export let chartData;
    const showDialog = () => {
        open(Dialog,
            { message: 'Save As', chartData: chartData}
        );
    };
</script>

<div on:click={showDialog} style="cursor: pointer; height: 30px"><a><GiBasketballBasket></GiBasketballBasket></a></div>