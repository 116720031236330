<script>
    export let titlePart1, titlePart2;
</script>

<div id="header">
    <div id="header-container">
        <a id="header-title" href="/">
            <span id="title-part-1">{titlePart1}</span> <span id="title-part-2">{titlePart2}</span>
        </a>
        <div id="header-menu">
            <slot />
        </div>
    </div>
    <div id="header-sub-line"></div>
</div>

<style>
    #header {
        box-shadow: 0 -0.4rem 1rem 0.2rem rgba(0, 0, 0, 0.6);
        margin-bottom: 20px;
    }
    #header-container {
        height: 40px;
        background: #000100;
        display: block;
    }
    #header-title {
        float: left;
        margin: 5px 10px 10px 20px;
        font-size: 1.5em;
    }
    #title-part-1 {
        font-family: "BentonSans Medium", sans-serif;
        color: #EEEEEE
    }
    #title-part-2 {
        font-family: "BentonSans Book", sans-serif;
        color: #A6192E;
    }
    #header-menu {
        float: right;
        margin-top: 5px;
        margin-right: 15px;
        display: inline-block;
    }
    #header-sub-line {
        margin-top: 3px;
        width: 100%;
        height: 5px;
        background: #A6192E;
    }
</style>