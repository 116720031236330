<script>
    export let data;  // format: list of dicts with the same keys
    export let error_message;
    console.log(data)
    import Cell from "./Cell.svelte";
</script>
{#if !data || data.length === 0}<h4>{error_message}</h4>
{:else}
    {#each data as rc}
        {#if rc['rows'].length === 0}
            <h3>No rows available</h3>
        {:else}
            <div class="table-container">
                <table>
                    <tr class="card_title">
                        <Cell colspan={rc['rows'][0].length} data={rc['title']}/>
                    </tr>
                    {#each rc['rows'] as r, i}
                        {#if rc['splits'][i]}
                            <tr class="card_section">
                                <Cell colspan={rc['rows'][0].length} data={rc['splits'][i]}/>
                            </tr>
                        {/if}
                        {#if i === 0 || rc['splits'][i]}
                            <tr class="card_headers">
                                {#each rc['headers'] as h}
                                    <Cell colspan={h[1]} data={h[0]}/>
                                {/each}
                            </tr>
                        {/if}
                        <tr class="row-{i%2}">
                            {#each r as x, j}
                                <Cell data={x[0]} unit={x[1]}/>
                            {/each}
                        </tr>
                    {/each}
                    <tr class="bottom_line">
                        <td colspan="{rc['rows'][0].length}"></td>
                    </tr>
                </table>
            </div>
        {/if}
        <br/>
    {/each}
{/if}

<style>
    .table-container {
    }

    table {
        border-collapse: collapse;
    }

    tr, td {
        margin-left: 3px;
        padding: 5px 8px 5px 12px;
        text-align: center;
    }

    tr.row-1 {
        background: #EEEEEE
    }

    .subscript {
        font-size: 10px;
        margin: 0px;
    }

    .card_title {
        background: #000100;
        color: white;
        font-family: "BentonSans Medium", sans-serif;
        text-transform: uppercase;
        font-size: 0.85em;
        text-align: center;
    }

    .card_headers {
        background: #DDDDDD;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.7em;
        text-align: center;
    }

    .card_section {
        background: #666666;
        color: #EEEEEE;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.7em;
        text-align: center;
    }

    .bottom_line {
        background: #000100;
        height: 5px;
        width: 100%;
    }

    td.icon {
        height: 20px;
        width: 20px;
    }
</style>
