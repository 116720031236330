<script>
    import Header from './components/Header.svelte';
    import ReportCard from './components/ReportCard.svelte';
    import Spinner from './components/Spinner.svelte';
    import Table from './components/Table.svelte';

    export let id;
    let data = 'N/A';

    async function game_report(id) {
        const res = await fetch('/api/game_report/' + id)
        let sdata = await res.json();
        data = JSON.parse(sdata);
        console.log(data);
    }

    game_report(id);
    let nth = (n) => {
        return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th"
    }
</script>

<svelte:head><title>Game Report</title></svelte:head>
{#if data === 'N/A'}
    <Header titlePart1="GAME" titlePart2="REPORT"/>
{:else}
    <Header titlePart1="{data['game_info']['away_team']} {data['game_info']['away_score']} @ {data['game_info']['home_team']} {data['game_info']['home_score']}"
            titlePart2=" &nbsp;&nbsp; {data['game_info']['period']}{nth(parseInt(data['game_info']['period']))} - {data['game_info']['game_clock']} "/>
{/if}

<div id="content">
    {#if data === 'N/A'}
        <Spinner/>
    {:else}
        <h1>{data['game_info']['home_team']}</h1>
        <ReportCard data={data['home']['overall']}/><br/>
        <ReportCard data={data['home']['actions']}/><br/>
        <ReportCard data={data['home']['closeouts']} error_message={"Closeouts doesn't meet threshold."}/><br/>
        <h3>Crashing</h3>
        <Table data={data['home']['crashing']} enableFilters={false} thresholdValue="5" /><br/>
        <h1>{data['game_info']['away_team']}</h1>
        <ReportCard data={data['away']['overall']}/><br/>
        <ReportCard data={data['away']['actions']}/><br/>
        <ReportCard data={data['away']['closeouts']} error_message={"Closeouts doesn't meet threshold."}/><br/>
        <h3>Crashing</h3>
        <Table data={data['away']['crashing']} enableFilters={false} thresholdValue="5" />
    {/if}
</div>

<style>
    #content {
        margin: 0 20px 20px 20px;
    }

    h1 {
        font-size: 24px;
        font-family: "BentonSans Black", sans-serif;
        color: #000100;
    }

    h3 {
        padding: 5px;
        background: #000100;
        color: white;
        font-family: "BentonSans Medium", sans-serif;
        text-transform: uppercase;
        font-size: 0.85em;
        text-align: center;
    }
</style>