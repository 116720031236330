<script>
    import Header from './components/Header.svelte';
    import ReportCard from './components/ReportCard.svelte';
    import Spinner from './components/Spinner.svelte';


    export let id;
    let data = 'N/A';
    let checkbox = false;
    async function team_report(id) {
        data = 'N/A';
        let res;
        if (checkbox) {
            res = await fetch('/api/team_def_report/' + id + '/vs_def')
        }
        else {
            res = await fetch('/api/team_def_report/' + id + '/def')
        }
        let sdata = await res.json();
        data = JSON.parse(sdata);
        console.log(data);
    }

    team_report(id);
    let nth = (n) => {
        return ["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th"
    }
</script>

<svelte:head><title>Defensive Report - {id}</title></svelte:head>
<Header titlePart1="DEFENSIVE" titlePart2="REPORT" />

<div id="content">
        <h1>{id}</h1>
        <label>
            <input type=checkbox bind:checked={checkbox} on:change={e => {team_report(id)}}>
            vs POR Filter
        </label>
        {#if data === 'N/A'}
            <Spinner />
        {:else}
            <h2>Overview</h2>
            <ReportCard data={data['defense']['overview']} error_message={"Overview not available"}/><br/>
            <h2>Transition</h2>
            <ReportCard data={data['defense']['transition']} error_message={"There are no distinguished transition behaviors"}/><br/>
            <h2>Post</h2>
            <ReportCard data={data['defense']['post_players']} error_message={"There are no notable post threats"}/><br/>
            <h2>Iso</h2>
            <ReportCard data={data['defense']['iso_players']} error_message={"There are no notable isolation threats"}/><br/>
            <h2>Pick and Roll w/5</h2>
            <h5>Ballhandlers</h5>
            <ReportCard data={data['defense']['pr_ballhandlers']} error_message={"There are no notable PR ballhandler threats"}/><br/>
            <h5>Screeners</h5>
            <ReportCard data={data['defense']['pr_screeners']} error_message={"There are no notable PR screener threats"}/><br/>
            <h2>Small Small PR</h2>
            <ReportCard data={data['defense']['sm_sm_ballhandlers']} error_message={"There are no notable small small threats"}/><br/>
            <h2>DHO</h2>
            <ReportCard data={data['defense']['dho_ballhandlers']} error_message={"There are no notable DHO threats"}/><br/>
            <h2>Screening Action</h2>
            <ReportCard data={data['defense']['screening_players']} error_message={"There are no notable off-ball threats"}/><br/>
            <h2>Closeouts</h2>
            <ReportCard data={data['defense']['closeout_players']} error_message={"There are no notable closeout threats"}/><br/>
            <h2>Zone</h2>
            <ReportCard data={data['defense']['zone']} error_message={"They have not seen zone this season"}/><br/>
        {/if}
</div>

<style>
    #content {
        margin: 0 20px 20px 20px;
    }

    h1 {
        font-size: 24px;
        font-family: "BentonSans Black", sans-serif;
        color: #000100;
    }

    h2 {
        font-size: 16px;
        font-family: "BentonSans Bold", sans-serif;
        color: #000100;
        background: #A6192E;
        padding: 4px;
        margin-bottom: 0;
    }

    h4 {
        font-size: 16px;
        font-family: "BentonSans Book", sans-serif;
        font-style: italic;
        padding: 4px;
    }

    h5 {
        font-size: 16px;
        font-family: "BentonSans Medium", sans-serif;
        color: #A6192E;
        background: #000100;
        padding: 4px;
    }
</style>