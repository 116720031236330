<script>
    import FormMetricGroup from "./FormMetricGroup.svelte";
    import MdAdd from 'svelte-icons/md/MdAdd.svelte';
    import MdPlayArrow from 'svelte-icons/md/MdPlayArrow.svelte';
    export let form, handleSubmit, filters, metrics;
    let addMetricGroup = () => {
        $form = $form.concat({
            metrics: undefined,
            dimensions: [{name: undefined, op: undefined, values: undefined, params: 0}],
            filters: [{name: undefined, op: undefined, values: undefined, params: 0}],
            ratio: null
        });
    }
</script>

<form>
    <div class="button-group">
        <div id="run_button" class="icon" on:click={handleSubmit} title="Run Query"><MdPlayArrow /></div>
    </div>
    {#each Array($form.length) as _, j}
        <FormMetricGroup form={form} id={j} filters={filters} metrics={metrics}/>
    {/each}
    <div class="icon" on:click={addMetricGroup}><MdAdd /></div>
</form>

<style>
    form {
        margin: 50px 15px;
    }
    .button-group {
        float: right;
        margin-right: 10px;
        margin-top: -35px;
    }
    #run_button {
        background-color: #DCDDDE; color: #FFFFFF;
    }
    #run_button:hover {
        background-color: #DCDDDE60; color: #FFFFFF;
    }
    #run_button:active {
        background-color: #DCDDDE60; color: #FFFFFF;
    }
    .icon {
        margin: 0px 5px 5px 10px;
        color: #333;
        width: 15px;
        height: 15px;
        padding: 5px;
        background: #bfbfbf57;
        border-radius: 15px;
    }
    .icon:hover {
        color: white;
        background: #DCDDDE60;
        cursor: pointer;
    }
</style>