<script>
    import MdLocalMovies from 'svelte-icons/md/MdLocalMovies.svelte'
    import Modal from "svelte-simple-modal";
    import ShotChartContent from "./ShotChartContent.svelte";

    export let data;  // format: list of dicts with the same keys
    export let enableFilters = false; // boolean
    export let thresholdValue = '';
    // Sorting
    let sortBy = {col: 'Poss', ascending: -1};
    const sortComp = (a, b) => {
        const aa = (isNaN(parseFloat(a[sortBy.col]))) ? a[sortBy.col] : parseFloat(a[sortBy.col].replace(',', ''));
        const bb = (isNaN(parseFloat(b[sortBy.col]))) ? b[sortBy.col] : parseFloat(b[sortBy.col].replace(',', ''));
        return (aa < bb) ? -1 * sortBy.ascending : (aa > bb) ? 1 * sortBy.ascending : 0;
    }
    const sortColumn = (column) => {
        if (sortBy.col == column) sortBy.ascending = sortBy.ascending * -1;
        else sortBy = {col: column, ascending: -1};
        filteredData = filteredData.sort(sortComp);
    }

    let getFloat = (x) => {
        try {
            let fl = parseFloat(x.replace(',', ''))
            return fl ? fl : ''
        } catch {
            return x
        }
    }

    // Filtering
    let filteredData = data;
    let thresholdMetricList = (data && data.length > 0) ? Object.keys(data[0]).filter(k => Number.isFinite(parseFloat(data[0][k]))) : [];
    let thresholdMetric = (thresholdMetricList.length > 0) ? thresholdMetricList[0] : undefined;
    let searchTerm = '';
    $: filteredData = data && data
        .filter(item => (Object.values(item).join('#').toLowerCase()).indexOf(searchTerm.toLowerCase()) !== -1)
        .filter(item => (Object.keys(item).indexOf(thresholdMetric) == -1 || getFloat(item[thresholdMetric]) >= thresholdValue))
        .sort(sortComp);
    let rankColor = (x) => {
        if (x > 25) return "blue-rank";
        else if (x < 6) return "red-rank";
        else return "";
    }
</script>

{#if data && data.length > 0}
    {#if enableFilters}
        <div class="table-filters">
            <input placeholder={'Filter'} bind:value={searchTerm}/>
            <select bind:value={thresholdMetric}>
                {#each thresholdMetricList as tm}
                    <option value={tm}>{tm}</option>
                {/each}
            </select>
            <input placeholder={'Min'} bind:value={thresholdValue} style="width: 50px"/>
        </div>
    {/if}

    <div class="table-container">
        <table>
            <thead>
            <tr>
                <th>Rank</th>
                {#each Object.keys(data[0]) as k}
                    {#if sortBy.col == k}
                        <th on:click={sortColumn(k)} class="sortedBy">{k}</th>
                    {:else if !k.endsWith("_rank")}
                        <th on:click={sortColumn(k)}>{k}</th>
                    {/if}
                {/each}
            </tr>
            </thead>
            <tbody>
            {#each filteredData as row, i}
                <tr class="row-{i%2}">
                    <td class="rown-{i}">{i + 1}</td>
                    {#each Object.keys(row) as k}
                        {#if row[k] !== null && row[k].toString().substring(0, 4) == 'http'} <!-- Video Link -->
                            <td class="icon">
                                <a href="{row[k]}" target="_blank">
                                    <MdLocalMovies/>
                                </a>
                            </td>
                        {:else if row[k] !== null && row[k].toString().substring(0, 2) === '[{'} <!-- Start of shot chart array -->
                            <td class="icon">
                                <Modal>
                                    <ShotChartContent chartData={row[k]}/>
                                </Modal>
                            </td>
                        {:else if !k.endsWith('_rank')}
                            <td class="rown-{i}">
                                {row[k]}
                                {#if row[k + '_rank'] !== undefined}<span class="rank {rankColor(row[k + '_rank'])}">&nbsp;{row[k + '_rank']}</span>{/if}
                            </td>
                        {/if}
                    {/each}
                </tr>
            {/each}
            </tbody>
        </table>
    </div>
{/if}

<style>
    /* Filters */
    .table-filters {
        background: #EEEEEE;
        height: 38px;
        margin-bottom: 15px;
    }

    input, select {
        float: left;
    }

    /* Table */
    .table-container {
        height: 100%;
        overflow: scroll;
    }

    table, th, td {
        border-collapse: collapse;
        text-align: center;
        min-width: 50px;
    }

    th {
        position: sticky;
        top: 0px;
        font-family: "BentonSans Medium", sans-serif;
        color: #000100;
        background: #f9f9f9;
        padding: 10px;
        border-bottom: #000100 2px solid;
        cursor: pointer;
    }

    th:hover, th:active {
        color: #A6192E;
    }

    th.sortedBy {
        background: #EEEEEE;
    }

    tr.row-1 {
        background: #EEEEEE;
    }

    tr:hover {
        background: #A6192E50;
    }

    td {
        padding: 4px;
        font-family: "GT America Mono Thin", "Courier New";
    }

    td.icon {
        height: 20px;
        width: 20px;
    }

    a {
        color: #000100;
    }

    a:hover {
        color: #A6192E;
    }

    span.rank {
        font-size: 9px;
    }

    span.blue-rank {
        color: blue;
    }

    span.red-rank {
        color: red;
    }
</style>
