<script>
    import MdLocalMovies from 'svelte-icons/md/MdLocalMovies.svelte';
    import Modal from 'svelte-simple-modal';
    import ShotChartContent from './ShotChartContent.svelte';

    export let colspan;
    export let data = '';
    export let unit = '';
    // Types are video and shot_chart
    let cell_type = '';
    if (data && data.toString().substring(0, 4) === 'http') {
        cell_type = 'video';
    } else if (data && data.toString().substring(0, 2) === '[{') {
        cell_type = 'shot_chart';
    }
</script>

<td
        class="{cell_type ? 'icon': ''}"
        colspan="{colspan}"
>
    {#if cell_type === 'video'}
        <a href="{data}" target="_blank">
            <MdLocalMovies/>
        </a>
    {:else if cell_type === 'shot_chart'}
        <Modal>
            <ShotChartContent chartData={data}/>
        </Modal>
    {:else}
        {#if (data === '0.0%' && unit === 'of shots') || data === 'nan%'}
            -
        {:else}
            {data}
        {/if}
        {#if unit}
            <br/><span class="subscript">{unit}</span>
        {/if}
    {/if}
</td>

<style>
    td {
        margin-left: 3px;
        padding: 5px 8px 5px 12px;
        text-align: center;
    }

    .subscript {
        font-size: 10px;
        margin: 0px;
    }

    td.icon {
        height: 20px;
        width: 20px;
    }
</style>