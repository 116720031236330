<script>
    import {onMount} from 'svelte';
    import "../charts/d3.basketball-shot-chart.css";
    import * as d3 from 'd3';
    import 'd3.chart';
    import "../charts/d3.charts.defaults.js";
    import "../charts/d3.basketball-shot-chart.js";
    export let message, chartData;
    chartData = JSON.parse(chartData.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": '));
    onMount(async () => {
        d3.select(document.getElementById('chart'))
            .append("svg")
            .chart("BasketballShotChart", {
              width: 600,
              title: '',
              hexagonBinVisibleThreshold: 0,
              heatScale: d3.scale.quantile()
                .domain([0.65, 1.35])
                .range(['#5458A2', '#6689BB', '#FADC97', '#F08460', '#B02B48']),
            }).draw(chartData);
        }
    )
</script>

<div id="chart"></div>

<style>
    #dialog-title {
        margin-bottom: 10px;
        color: #DCDDDE;
        text-transform: uppercase;
        font-family: "BentonSans Medium", sans-serif;
        font-size: 0.8em;
    }

    input {
        width: 270px;
        border: 0px;
    }

    button {
        font-family: "BentonSans Medium", sans-serif;
        margin-top: 5px;
        font-size: 0.7em;
        color: #f9f9f9;
        padding: 8px 8px 5px 8px;
        text-transform: uppercase;
        vertical-align: middle;
        border: none;
        border-radius: 30px;
        background: #DCDDDE;
    }

    button:hover {
        cursor: pointer;
        background: #DCDDDE60;
    }
</style>